//公共产品管理列表
export const cols = [
  {
    prop: 'description',
    label: '风格名称'
  },
  {
    label: '状态',
    slotName: 'stateSlot'
  }
]
